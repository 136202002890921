// Data *
// Empresa *
// Contato 
// Telefone *
// Ramal 
// email *
// Depto 
// Responsavel tecnico *  
// Contratante *

import { Input, TextField } from "@mui/material";




export function Dados({data, updateField}) {
    
    return (
        <div>
            <div className="form-control">
                <label htmlFor="date">Data:<span>*</span></label>
                <TextField
                    type="date"
                    id="date"
                    required
                    value={data.date || ""}
                    onChange={(e) => updateField("date", e.target.value)}
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Empresa:<span>*</span></label>
                <TextField 
                    type="text"
                    name="text"
                    id="enterprise"
                    required
                    value={data.enterprise || ""}
                    onChange={(e) => updateField("enterprise", e.target.value)}
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Contato:<span>*</span></label>
                <TextField 
                    type="text"
                    name="text"
                    id="contact"
                    required
                    value={data.contact || ""}
                    onChange={(e) => updateField("contact", e.target.value)}
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Telefone:</label>
                <TextField 
                    type="tel"
                    name="phone"
                    id="phone"
                    value={data.phone || ""}
                    inputProps={{maxLength: 20}}
                    onChange={(e) => updateField("phone", e.target.value)}
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Ramal:</label>
                <TextField 
                    type="text"
                    name="text"
                    id="ramal"
                    value={data.ramal || ""}
                    inputProps={{maxLength: 6}}
                    onChange={(e) => updateField("ramal", e.target.value)}
                    size="small"
                />
            </div>
            <div className="form-control">
                <label>E-mail:</label>
                <TextField 
                    type="email"
                    name="email"
                    id="email"
                    value={data.email || ""}
                    onChange={(e) => updateField("email", e.target.value)}
                    size="small"
                />
            </div>
            <div className="form-control">
                <label>Departamento:<span>*</span></label>
                <TextField 
                    type="text"
                    name="text"
                    id="department"
                    required
                    value={data.department || ""}
                    onChange={(e) => updateField("department", e.target.value)}
                    size="small"
                />
            </div>
            <div className="form-control">
                <label>Responsável técnico:<span>*</span></label>
                <TextField 
                    type="text"
                    name="text"
                    id="technical-manager"
                    required
                    value={data.technical_manager || ""}
                    onChange={(e) => updateField("technical_manager", e.target.value)}
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Contratante:<span>*</span></label>
                <TextField 
                    type="text"
                    name="text"
                    id="contractor"
                    required
                    value={data.contractor || ""}
                    onChange={(e) => updateField("contractor", e.target.value)}
                    size='small'
                />
            </div>
        </div>
        
    )
}