
import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const formatDate = (date) => {
    const [year, month, day] = date.split('-');

    return `${day}/${month}/${year}`
}




export function Emitir({ data }) {

    const [open, setOpen] = useState(false);

    let fileName = '';

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    async function generatePDF() {

        try {

            let token = localStorage.getItem('access_token');

            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }

            let bodyContent = JSON.stringify(data);

            console.log(data);

            let reqOptions = {
                // url: "http://localhost:8000/api/v1/report/generate",
                url: "https://api.reportapi.top/api/v1/report/generate",
                method: "POST",
                headers: headersList,
                data: bodyContent,
                responseType: 'blob'
            }

            let response = await axios.request(reqOptions);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName + '.pdf'

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: error.response ? error.response.data : error.message,
                confirmButtonText: 'OK',
            });
        }


    }
    return (
        <div>
            <div class="container">

                <div>
                    <h2 style={{ textAlign: 'center' }}>Informaçõs Gerais</h2>
                    <table style={{ margin: '0 auto', borderSpacing: '20px' }} class="info-table">

                        <tr>
                            <th>Data</th>
                            <td>{formatDate(data.date)}</td>
                        </tr>
                        <tr>
                            <th>Empresa</th>
                            <td>{data.enterprise}</td>
                        </tr>
                        <tr>
                            <th>Contato</th>
                            <td>{data.contact}</td>
                        </tr>
                        <tr>
                            <th>Telefone</th>
                            <td>{data.phone}</td>
                        </tr>
                        <tr>
                            <th>Ramal</th>
                            <td>{data.ramal}</td>
                        </tr>
                        <tr>
                            <th>Departamento</th>
                            <td>{data.department}</td>
                        </tr>
                        <tr>
                            <th>Responsável técnico</th>
                            <td>{data.technical_manager}</td>
                        </tr>
                        <tr>
                            <th>Contratante</th>
                            <td>{data.contractor}</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h2 style={{ textAlign: 'center' }}>Serviços</h2>
                    <table style={{ margin: '0 auto', borderSpacing: '20px' }}>

                        <tr>
                            <th>Serviço executado</th>
                            <td>{data.executed_service}</td>
                        </tr>
                        <tr>
                            <th>Ramo da atividade</th>
                            <td>{data.branch_service}</td>
                        </tr>
                        <tr>
                            <th>Modelo da máquina</th>
                            <td>{data.model_machine}</td>
                        </tr>
                        <tr>
                            <th>Modelo do comando da máquina</th>
                            <td>{data.model_command}</td>
                        </tr>
                        <tr>
                            <th>Número de eixos da máquina</th>
                            <td>{data.axes_number}</td>
                        </tr>
                        <tr>
                            <th>Localização da máquina na planta</th>
                            <td>{data.machine_location}</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h2 style={{ textAlign: 'center' }}>Relatório de horas</h2>
                    <table style={{ margin: '0 auto' }}>

                    </table>
                </div>

                <div>

                    <h2 style={{ textAlign: 'center' }}>Valores</h2>

                    <table style={{ margin: '0 auto', borderSpacing: '20px' }}>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.values.map((item, index) => (

                                <tr>
                                    <td>{formatDate(item.date)}</td>
                                    <td>{item.description}</td>
                                    <td>{item.value}</td>
                                    <td>{item.motive}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <h2 style={{ textAlign: 'center' }}>Descrição</h2>
                    <table style={{ margin: '0 auto' }}>
                        {
                            data.schedule.map((item) => (
                                <div>
                                    <h4><center>{formatDate(item['date'])}</center></h4>
                                    <div dangerouslySetInnerHTML={{ __html: data.description[item['date']] }}></div>
                                </div>
                            ))
                        }


                    </table>
                </div>


            </div>

            <div>
                <Button onClick={handleOpen} variant="contained">
                    <span>Gerar PDF</span>
                </Button>
            </div>
            <Dialog open={open} onClose={handleClose} PaperProps={{component: 'form', onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                fileName = formJson.filename;
                generatePDF();
                handleClose();
                }}}>
                <DialogTitle>Digite o nome do arquivo</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        type="text"
                        required
                        fullWidth
                        name='filename'
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type="submit">Download</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}