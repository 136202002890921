import './App.css';
import React from 'react';
import { LoginForm } from './components/LoginForm';
import { Form } from './components/Form'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/AuthContext';


function App() {

  const { isLoggedIn } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path='/' element={isLoggedIn ? <Navigate to='/report' /> : <LoginForm />} />
        <Route path='/report' element={isLoggedIn ? <Form /> : <Navigate to='/' />} />
      </Routes>
    </Router>
  );
}

export default App;


