import React from "react";



const TopBar = () => {
    return (
        <div style={{textAlign: "center", marginBottom: "1.5rem", fontSize: "1.5rem"}}>
            <h2></h2>
        </div>
    )
}

export default TopBar;