
import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Services } from './Services';
import { Values } from './Values';
import { Descrição } from './Descricao';
import { Dados } from './Dados';
import { Emitir } from './Emitir';
import { useForm } from './UseForm';
import TopBar from '../global/TopBar'
import Header from './Header';
import { Scheduler } from './Scheduler';
import { Button } from '@mui/material';
import './Form.css'

const formTemplate = {
    date: "",
    enterprise: "",
    contact: "",
    phone: "",
    ramal: "",
    email: "",
    department: "",
    technical_manager: "",
    contractor: "",
    executed_service: "",
    branch_service: "",
    category_service: "",
    model_machine: "",
    model_command: "",
    axes_number: "",
    machine_location: "",
    schedule: [],
    values: [],
    description: {}
  }


export function Form() {
    const [data, setData] = useState(formTemplate);

  const updateField = (key, value) => {
    setData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const formComponents = [<Dados data={data} updateField={updateField} />, <Services data={data} updateField={updateField} />, <Scheduler data={data} updateField={updateField} />, <Values data={data} updateField={updateField} />, <Descrição data={data} updateField={updateField} />, <Emitir data={data} />];

  const { currentStep, currentComponent, changeStep, isLastStep, isFirstStep } = useForm(formComponents);

  return (
    <div>
      <TopBar />
      <Header currentStep={currentStep} />
      <div className='form-container'>
        <form onSubmit={(e) => changeStep(currentStep + 1, e)}>
          <div>{currentComponent}</div>
          <div className='actions'>
            {!isFirstStep && (
              <Button type="button" onClick={() => changeStep(currentStep - 1)} style={{ left: 0 }}>
                <ArrowBackIosIcon />
                <span>Voltar</span>
              </Button>
            )}
            {!isLastStep && (
              <Button type="submit">
                <span>Avançar</span>
                <ArrowForwardIosIcon />
              </Button>
            )}
          </div>
        </form>
      </div>

    </div>

  );
};