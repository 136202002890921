import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';


export function Scheduler({ data, updateField }) {

    const [rows, setRows] = useState(data.schedule);

    const [scheduler, setScheduler] = useState({
        date: '',
        horario_chegada: '',
        horario_saida: '',
        km_ida_inicio: '',
        km_ida_fim: '',
        km_volta_inicio: '',
        km_volta_fim: ''
    });

    // Função para adicionar nova linha
    const addRow = () => {
        setRows([...rows, scheduler]);
        setScheduler({
            date: '',
            horario_chegada: '',
            horario_saida: '',
            km_ida_inicio: '',
            km_ida_fim: '',
            km_volta_inicio: '',
            km_volta_fim: ''
        });
        updateField('schedule', rows);

    };

    // Função para lidar com mudanças nos campos de entrada
    const handleChange = (index, field, value) => {
        const newScheduler = [...rows];
        newScheduler[index][field] = value;
        updateField('schedule', newScheduler);
        setRows(newScheduler);
    };

    const delScheduler = (index) => {
        const newDataScheduler = rows.filter((_, i) => i !== index);
        setRows(newDataScheduler);
        updateField('schedule', newDataScheduler);  
    }


    return (
        <TableContainer style={{width: '100%'}}>
            <Table style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>
                            Data 
                        </TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Horário de chegada</TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Horário de saída</TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Quilometragem de ida</TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Quilometragem de volta</TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(rows).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{fontSize: '12px'}}>
                                <TextField
                                    type="date"
                                    value={rows[row].date}
                                    onChange={(e) => handleChange(index, 'date', e.target.value)}
                                    sx={{ width: '140px', fontSize: '8px'}}
                                    size="small"
                                    required
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="time"
                                    value={rows[row].horario_chegada}
                                    onChange={(e) => handleChange(index, 'horario_chegada', e.target.value)}
                                    size="small"
                                    style={{ width: '100%', fontSize: '14px' }}
                                    required
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="time"
                                    value={rows[row].horario_saida}
                                    onChange={(e) => handleChange(index, 'horario_saida', e.target.value)}
                                    size="small"
                                    style={{ width: '100%', fontSize: '14px' }}
                                    required 
                                />
                            </TableCell>
                            <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                type="number"
                                    value={rows[row].km_ida_inicio}
                                    onChange={(e) => handleChange(index, 'km_ida_inicio', e.target.value)}
                                    placeholder="Início"
                                    style={{ width: '100px', marginRight: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                -
                                <TextField
                                    type="number"
                                    value={rows[row].km_ida_fim}
                                    onChange={(e) => handleChange(index, 'km_ida_fim', e.target.value)}
                                    placeholder="Fim"
                                    style={{ width: '100px', marginLeft: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                </div>
                            </TableCell>
                            <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                type="number"
                                    value={rows[row].km_volta_inicio}
                                    onChange={(e) => handleChange(index, 'km_volta_inicio', e.target.value)}
                                    placeholder="Início"
                                    style={{ width: '100px', marginRight: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                -
                                <TextField
                                type="number"
                                    value={rows[row].km_volta_fim}
                                    onChange={(e) => handleChange(index, 'km_volta_fim', e.target.value)}
                                    placeholder="Fim"
                                    style={{ width: '100px', marginLeft: '4px', fontSize: '14px' }}
                                    size="small"
                                    required      
                                />
                                </div>
                            </TableCell>
                            <TableCell >
                                <Button onClick={() => delScheduler(index)}>
                                    <DeleteForeverOutlinedIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    )
                    )}
                </TableBody>
            </Table>

            <Button onClick={addRow} variant="contained" color="primary" size="small" style={{marginLeft: '15px'}}>
                Adicionar
            </Button>

        </TableContainer>
    );
};