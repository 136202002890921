// Descrição das atividades realizadas (espaço livre de texto)

import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa o CSS padrão do Quill


const formatDate = (date) => {
  const [year, month, day] = date.split('-');

  return `${day}/${month}/${year}`
}


export function Descrição({ data, updateField }) {

  const [value, ] = useState(data.description || data.schedule.reduce((acc, item) => {
    acc[item.data] = '';
    return acc;
  }, {}));

  const handleChange = (date, val) => {
    value[date] = val;
    updateField('description', value);
  };

  return (
    <div>
      {data.schedule.map((item) => (
        <>
          <h4><center>{formatDate(item['date'])}</center></h4>
          <div>
            <ReactQuill
              value={value[item.date]}
              onChange={(value) => handleChange(item.date, value)}
              modules={Descrição.modules}
              formats={Descrição.formats}
            />
          </div>
        </>
      ))}
      <br />

    </div>
  );
};

Descrição.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    ['link']
  ],
};

Descrição.formats = [
  'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'link'
];

