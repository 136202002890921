import React from "react";
import "./Header.css";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const Header = ({currentStep}) => {
    return (
        <div className="steps">
            <div className="step active"><Person2RoundedIcon /><p>Dados</p></div> 
            <div className={`step ${currentStep >= 1 ? "active" : ""}`}><SettingsRoundedIcon /><p>Serviços</p></div>
            <div className={`step ${currentStep >= 2 ? "active" : ""}`}><CalendarMonthOutlinedIcon /><p>Horário</p></div>
            <div className={`step ${currentStep >= 3 ? "active" : ""}`}><AttachMoneyRoundedIcon/><p>Valores</p></div>
            <div className={`step ${currentStep >= 4 ? "active" : ""}`}><DescriptionRoundedIcon/><p>Descrição</p></div>
            <div className={`step ${currentStep >= 5 ? "active" : ""}`}><PictureAsPdfRoundedIcon/><p>Emitir</p></div>
        </div>
    )
}

export default Header;