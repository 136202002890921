import React, { useState } from "react";
import './LoginForm.css';
import { Button } from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Swal from "sweetalert2";
import axios from "axios";
import { useAuth } from './AuthContext';


export function LoginForm() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    async function handleLogin(e) {
        e.preventDefault();

        try {

            let headersList = {
                "Accept": "*/*"
               }

            let formdata = new FormData();
            formdata.append("username", email);
            formdata.append("password", password);

            let bodyContent =  formdata;

            let reqOptions = {
            url: "https://api.reportapi.top/api/v1/user/login",
            // url: "http://localhost:8000/api/v1/user/login",
            method: "POST",
            headers: headersList,
            data: bodyContent
            }

            let response = await axios.request(reqOptions);
            localStorage.setItem('access_token', response.data.access_token);
            // localStorage.setItem('loggedIn', true);
            login();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Dados errados'
            }

            );
        }


    }

    return (
        <div className="login-container">
            <div className="wrapper">
            <form onSubmit={handleLogin}>
                <div className="input-box">
                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                    <PersonOutlineOutlinedIcon className="icon" />
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} required />
                    <LockOutlinedIcon className="icon" />
                </div>

                <Button type="submit">Entrar</Button>
            </form>
        </div>
        </div>
        
    )
};