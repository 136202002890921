// Serviço executado: Diagnostico - Corretiva - Preventiva - Outro. Qual?
// Ramo da atividade: Mecânica - Elétrica - Eletrônica - Software - Outro. Qual?
// Modelo da maquina
// Modelo do comando da maquina
// Numero de eixos da maquina
// Referência/Localização da Máquina na planta. (Imagem?)
// Relatorio de horas

import { Autocomplete, FormControl, FormControlLabel, FormLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useState } from "react";


const services = ['Diagnóstico', 'Corretiva', 'Preventiva', 'Preditiva', 'Diagnóstico e preventiva', 'Diagnóstico e preditiva']


export function Services({ data, updateField }) {


    const [selectedValueCategory, setSelectedValueCategory] = useState(data.category_service);
    const [selectedValueBranch, setSelectedValueBranch] = useState(data.branch_service);
    const [selectedValueExecuted, setSelectedValueExecuted] = useState(data.executed_service);


    const handleSelectExecuted = (value) => {

        updateField('executed_service', value);
        setSelectedValueExecuted(value);
    };

    const handleSelectCategory = (e) => {

        updateField('category_service', e.target.value);
        setSelectedValueCategory(e.target.value);
    }

    const handleSelectBranch = (e) => {

        updateField('branch_service', e.target.value);
        setSelectedValueBranch(e.target.value);
    }

    return (

        <div>
            <div className="form-control-checkbox">
                <FormControl size="small" required>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>Serviço executado:</FormLabel>
                    <Autocomplete
                        options={services}
                        freeSolo 
                        value={selectedValueExecuted}
                        onInputChange={(event, newInputValue) => {
                            handleSelectExecuted(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Selecione ou digite um serviço" variant="outlined" />
                        )}
                        sx={{width: 300}}
                        size="small"
                    />

                </FormControl>
            </div>
            <div className="form-control-checkbox">
                <FormControl required>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>Categoria de serviço:</FormLabel>
                    <RadioGroup name="category_service" value={selectedValueCategory} row>
                        <FormControlLabel value="Spot" control={<Radio />} label="Spot" onChange={handleSelectCategory} sx={{ fontSize: '14px' }} />
                        <FormControlLabel value="Contrato" control={<Radio />} label="Contrato" onChange={handleSelectCategory} sx={{ fontSize: '14px' }} />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="form-control-checkbox">
                <FormControl required>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>Ramo da atividade:</FormLabel>
                    <RadioGroup name="branch_service" value={selectedValueBranch} row>
                        <FormControlLabel value="Mecânica" control={<Radio />} label="Mecânica" onChange={handleSelectBranch} />
                        <FormControlLabel value="Elétrica" control={<Radio />} label="Elétrica" onChange={handleSelectBranch} />
                        <FormControlLabel value="Software" control={<Radio />} label="Software" onChange={handleSelectBranch} />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="form-control">
                <label>Modelo da máquina:<span>*</span></label>
                <TextField
                    type="text"
                    name="text"
                    id="model-machine"
                    value={data.model_machine || ""}
                    onChange={(e) => updateField("model_machine", e.target.value)}
                    required
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Modelo do comando da máquina:<span>*</span></label>
                <TextField
                    type="text"
                    name="text"
                    id="model-command-machine"
                    value={data.model_command || ""}
                    onChange={(e) => updateField("model_command", e.target.value)}
                    required
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Número de eixos da máquina:<span>*</span></label>
                <TextField
                    type="number"
                    name="number"
                    id="number-spindle-machine"
                    value={data.axes_number || ""}
                    onChange={(e) => updateField("axes_number", e.target.value)}
                    inputProps={{ min: 1 }}
                    required
                    size='small'
                />
            </div>
            <div className="form-control">
                <label>Localização da máquina na planta: <span>*</span></label>
                <TextField
                    type="text"
                    name="text"
                    id="location-machine"
                    value={data.machine_location || ""}
                    onChange={(e) => updateField("machine_location", e.target.value)}
                    required
                    size='small'
                />
            </div>


        </div>
    )
}